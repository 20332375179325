  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-9-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>Balcon</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expertise balcon</h1>
<p>Votre Balcon Présente Des Fissures et Vous Avez Des Doutes Sur La Solidité Structurelle du Bien ? </p>

<div className='services-details-image'>
  <img src={KDPImagePage} alt="Balcon" />
</div>

<h2>Les désordres courants sur un balcon</h2>

<p>Dans de nombreuses villes, certains ouvrages et immeubles anciens possèdent des balcons. Ces ouvrages vieillissants sont exposés au vent, à la pluie qui finissent par générer des désordres sur l’ouvrage.</p>

<p>Ces désordres doivent faire l’objet d’une attention particulière car si évolutifs, ils peuvent être amenés à ruine, ce qui peut entraîner des conséquences parfois dramatiques.</p>

<p>C’est pourquoi, en cas de doutes, l’expertise d’un balcon par un Expert construction est indispensable. </p>

<h2>Pourquoi réaliser une expertise balcon ? </h2>

<p>Que ce soit dans le cadre d’une acquisition d’un bien, d’une vente, ou simplement d’obtenir un avis technique générale, l’expertise d’un balcon a pour objectif répondre à des questions légitimes :</p>

<ul><li>le balcon est-il dans un bon état structurel ?</li>
<li>le balcon présente des fissures, sont-elles graves ?</li></ul>

<p>Dans tous ces cas de figure, l’avis d’un expert technique est souvent indispensable.</p>

<p>En fonction des caractéristiques du balcon, de ses dimensions et ses matériaux et des pathologies constatées, l’Expert est apte à produire un rapport technique vous permettant de donner un avis technique général de l’ouvrage, de déterminer les causes d'éventuels désordres et des solutions techniques appropriées.</p>

<p>Si vous avez un doute sur l’état structurel de votre balcon, ne restez pas dans l’incertitude.</p>

<p> </p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert</a>
    </div>
</div>




                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/balcon/' className='active'>Balcon</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details